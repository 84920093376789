import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaCheck, FaUtensils, FaClock, FaCalendarAlt, FaLeaf, FaAppleAlt, FaCarrot, FaCookie, FaBrain, FaChartBar, FaExchangeAlt, FaLock, FaUser, FaCreditCard, FaShoppingCart, FaQuestion, FaMoon, FaSun } from 'react-icons/fa';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const plans = [
  {
    name: 'Basic',
    price: '₹1500',
    duration: 'per month',
    meals: 'One meal per day (Lunch)',
    description: 'Perfect for those who need a hearty lunch.',
    features: [
      '7 different meals per week',
      'Free delivery',
      '24/7 customer support',
      'Vegetarian options available'
    ],
    recommendedFor: 'Students, Work-from-home professionals',
    reviews: [
      { name: 'Rahul S.', rating: 4.5, comment: "Great for my busy workdays!" },
      { name: 'Priya M.', rating: 4, comment: "Wish there were more options, but overall good." }
    ],
    mealsPerWeek: 7
  },
  {
    name: 'Standard',
    price: '₹2000',
    duration: 'per month',
    meals: 'Two meals per day (Lunch & Dinner)',
    description: 'Ideal for busy professionals.',
    features: [
      '14 different meals per week',
      'Free delivery',
      '24/7 customer support',
      'Customize your menu',
      'Weekend special meals'
    ],
    recommendedFor: 'Working professionals, Couples',
    reviews: [
      { name: 'Amit K.', rating: 5, comment: "Love the variety and convenience!" },
      { name: 'Sneha R.', rating: 4.5, comment: "Great taste and prompt delivery." }
    ],
    mealsPerWeek: 14
  },
  {
    name: 'Premium',
    price: '₹3500',
    duration: 'per month',
    meals: 'Three meals per day (Breakfast, Lunch & Dinner)',
    description: 'Complete nutrition for your entire day.',
    features: [
      '21 different meals per week',
      'Free delivery',
      '24/7 customer support',
      'Customize your menu',
      'Weekly chef specials',
      'Nutritionist consultation'
    ],
    recommendedFor: 'Families, Health enthusiasts',
    reviews: [
      { name: 'Vikram S.', rating: 5, comment: "Worth every penny! Our family loves it." },
      { name: 'Anita D.', rating: 4.5, comment: "Nutritionist consultation is a game-changer." }
    ],
    mealsPerWeek: 21
  }
];

const cuisines = ['North Indian', 'South Indian', 'Chinese', 'Continental'];

const sampleMenu = [
  { 
    id: 1,
    name: 'Paneer Butter Masala with Naan', 
    cuisine: 'North Indian', 
    calories: 550, 
    image: '/api/placeholder/400/300',
    macros: { protein: 20, carbs: 50, fat: 30 },
    price: 180,
    ingredients: ['Paneer', 'Butter', 'Cream', 'Spices', 'Naan'],
    dietaryInfo: ['Vegetarian', 'Contains Gluten', 'Contains Dairy'],
    sustainabilityScore: 7.5
  },
  { 
    id: 2,
    name: 'Masala Dosa with Sambar', 
    cuisine: 'South Indian', 
    calories: 450, 
    image: '/api/placeholder/400/300',
    macros: { protein: 15, carbs: 60, fat: 20 },
    price: 150,
    ingredients: ['Rice', 'Lentils', 'Potatoes', 'Spices'],
    dietaryInfo: ['Vegetarian', 'Gluten-Free'],
    sustainabilityScore: 8.5
  },
  { 
    id: 3,
    name: 'Vegetable Hakka Noodles', 
    cuisine: 'Chinese', 
    calories: 400, 
    image: '/api/placeholder/400/300',
    macros: { protein: 12, carbs: 55, fat: 18 },
    price: 160,
    ingredients: ['Noodles', 'Mixed Vegetables', 'Soy Sauce', 'Spices'],
    dietaryInfo: ['Vegetarian', 'Contains Gluten'],
    sustainabilityScore: 8.0
  },
  { 
    id: 4,
    name: 'Grilled Chicken Salad', 
    cuisine: 'Continental', 
    calories: 350, 
    image: '/api/placeholder/400/300',
    macros: { protein: 30, carbs: 15, fat: 20 },
    price: 200,
    ingredients: ['Chicken Breast', 'Mixed Greens', 'Olive Oil', 'Balsamic Dressing'],
    dietaryInfo: ['High Protein', 'Low Carb', 'Gluten-Free'],
    sustainabilityScore: 9.0
  },
];

const SubscriptionPage = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedCuisine, setSelectedCuisine] = useState('All');
  const [wizardStep, setWizardStep] = useState(0);
  const [customizationOpen, setCustomizationOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [dietaryPreferences, setDietaryPreferences] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [calorieGoal, setCalorieGoal] = useState(2000);
  const [darkMode, setDarkMode] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);
  const [address, setAddress] = useState('');
  const [subscriptionDuration, setSubscriptionDuration] = useState('week');
  const [showAIQuestionnaire, setShowAIQuestionnaire] = useState(false);

  const filteredMenu = selectedCuisine === 'All' 
    ? sampleMenu 
    : sampleMenu.filter(item => item.cuisine === selectedCuisine);

  useEffect(() => {
    // Simulating fetching user's meal schedule
    if (isLoggedIn) {
      const userEvents = [
        {
          title: 'Breakfast',
          start: new Date(2024, 7, 17, 8, 0),
          end: new Date(2024, 7, 17, 9, 0),
        },
        {
          title: 'Lunch',
          start: new Date(2024, 7, 17, 13, 0),
          end: new Date(2024, 7, 17, 14, 0),
        },
        {
          title: 'Dinner',
          start: new Date(2024, 7, 17, 19, 0),
          end: new Date(2024, 7, 17, 20, 0),
        },
      ];
      setEvents(userEvents);
    }
  }, [isLoggedIn]);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setWizardStep(1);
  };

  const renderStars = (rating) => {
    return Array(5).fill(0).map((_, i) => (
      <span key={i} className={`text-yellow-400 ${i < Math.floor(rating) ? 'fas fa-star' : i < rating ? 'fas fa-star-half-alt' : 'far fa-star'}`}></span>
    ));
  };

  const handleLogin = () => {
    if (!showOtpInput) {
      // Simulating OTP send
      setShowOtpInput(true);
    } else {
      // Simulating OTP verification
      setIsLoggedIn(true);
      setShowOtpInput(false);
    }
  };

  const handlePayment = () => {
    // Simulating payment process
    setTimeout(() => {
      setWizardStep(5);
    }, 2000);
  };

  const handleMealSelect = (meal) => {
    if (selectedMeals.find(m => m.id === meal.id)) {
      setSelectedMeals(selectedMeals.filter(m => m.id !== meal.id));
    } else {
      if (selectedMeals.length < selectedPlan.mealsPerWeek) {
        setSelectedMeals([...selectedMeals, meal]);
      } else {
        alert(`You can only select up to ${selectedPlan.mealsPerWeek} meals for this plan.`);
      }
    }
  };

  const handleDietaryPreference = (preference) => {
    if (dietaryPreferences.includes(preference)) {
      setDietaryPreferences(dietaryPreferences.filter(p => p !== preference));
    } else {
      setDietaryPreferences([...dietaryPreferences, preference]);
    }
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    // Here you would typically call an address autocomplete API
  };

  const handleSubscriptionDurationChange = (duration) => {
    setSubscriptionDuration(duration);
    // Here you would typically update the price based on the duration
  };

  const getDiscountedPrice = () => {
    const basePrice = parseInt(selectedPlan.price.replace('₹', ''));
    if (subscriptionDuration === 'month') {
      return basePrice * 0.9; // 10% discount for monthly subscription
    }
    return basePrice;
  };

  const Hero = () => (
    <div className={`bg-gradient-to-r ${darkMode ? 'from-purple-800 to-indigo-900' : 'from-green-400 to-blue-500'} text-white py-20 px-4 sm:px-6 lg:px-8 mb-16`}>
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl md:text-6xl font-extrabold mb-4">Revolutionize Your Meals</h1>
        <p className="text-xl md:text-2xl mb-8">Experience AI-powered meal planning, personalized nutrition, and gourmet dishes delivered to your doorstep.</p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setShowAIQuestionnaire(true)}
          className={`${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-white text-green-500 hover:bg-green-100'} px-8 py-4 rounded-full text-lg font-semibold transition duration-300 shadow-lg`}
        >
          Start Your Culinary Journey
        </motion.button>
      </div>
    </div>
  );

  const AIQuestionnaire = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className={`bg-white p-8 rounded-lg shadow-xl max-w-md w-full ${darkMode ? 'bg-gray-800 text-white' : ''}`}
      >
        <h2 className="text-2xl font-bold mb-4">Personalize Your Experience</h2>
        <p className="mb-4">Let's get to know you better to provide personalized meal recommendations.</p>
        <div className="space-y-4">
          <div>
            <label className="block mb-2">What's your primary goal?</label>
            <select className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600' : ''}`}>
              <option>Weight loss</option>
              <option>Muscle gain</option>
              <option>Maintain weight</option>
              <option>Improve overall health</option>
            </select>
          </div>
          <div>
            <label className="block mb-2">Any dietary restrictions?</label>
            <div className="space-x-2">
              {['Vegetarian', 'Vegan', 'Gluten-free', 'Dairy-free'].map((diet) => (
                <label key={diet} className="inline-flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="ml-2">{diet}</span>
                </label>
              ))}
            </div>
          </div>
          <div>
            <label className="block mb-2">How active are you?</label>
            <select className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600' : ''}`}>
              <option>Sedentary</option>
              <option>Lightly active</option>
              <option>Moderately active</option>
              <option>Very active</option>
            </select>
          </div>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            setShowAIQuestionnaire(false);
            setWizardStep(1);
          }}
          className={`mt-6 w-full ${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-green-500 hover:bg-green-600'} text-white py-2 rounded-full transition duration-300`}
        >
          Get My Personalized Plan
        </motion.button>
      </motion.div>
    </motion.div>
  );

  const LoginModal = () => (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      className={`bg-white p-8 rounded-lg shadow-xl ${darkMode ? 'bg-gray-800 text-white' : ''}`}
    >
      <h2 className="text-2xl font-bold mb-4">Login</h2>
      {!showOtpInput ? (
        <>
          <input
            type="tel"
            placeholder="Enter your mobile number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className={`w-full p-2 border rounded mb-4 ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`}
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleLogin}
            className={`w-full ${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white py-2 rounded-full transition duration-300`}
          >
            Send OTP
          </motion.button>
        </>
      ) : (
        <>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className={`w-full p-2 border rounded mb-4 ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`}
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleLogin}
            className={`w-full ${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white py-2 rounded-full transition duration-300`}
          >
            Verify OTP
          </motion.button>
        </>
      )}
    </motion.div>
  </motion.div>
);

const SubscriptionWizard = () => (
  <div className={`max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8 mb-16 ${darkMode ? 'bg-gray-800 text-white' : ''}`}>
    <div className="flex justify-between mb-8">
      {['Select Plan', 'Customize', 'Choose Meals', 'Delivery', 'Payment', 'Confirm'].map((step, index) => (
        <div key={index} className={`flex flex-col items-center ${index <= wizardStep ? 'text-green-500' : 'text-gray-400'}`}>
          <div className={`w-8 h-8 rounded-full ${index <= wizardStep ? 'bg-green-500' : 'bg-gray-200'} flex items-center justify-center text-white mb-2`}>
            {index < wizardStep ? <FaCheck /> : index + 1}
          </div>
          <span className="text-sm">{step}</span>
        </div>
      ))}
    </div>
    <AnimatePresence mode="wait">
      {wizardStep === 0 && (
        <motion.div
          key="step0"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
        >
          <h2 className="text-2xl font-bold mb-4">Select Your Plan</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {plans.map((plan) => (
              <motion.div
                key={plan.name}
                className={`bg-white rounded-lg shadow-md p-6 cursor-pointer ${darkMode ? 'bg-gray-700' : ''}`}
                whileHover={{ scale: 1.05 }}
                onClick={() => handlePlanSelect(plan)}
              >
                <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                <p className="text-3xl font-bold text-green-500 mb-2">{plan.price}</p>
                <p className="text-gray-600 mb-4">{plan.meals}</p>
                <ul className="mb-4">
                  {plan.features.slice(0, 3).map((feature, index) => (
                    <li key={index} className="flex items-center mb-2">
                      <FaCheck className="text-green-500 mr-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`w-full ${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white py-2 rounded-full transition duration-300`}
                >
                  Select
                </motion.button>
              </motion.div>
            ))}
          </div>
        </motion.div>
      )}
      {wizardStep === 1 && (
        <motion.div
          key="step1"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
        >
          <h2 className="text-2xl font-bold mb-4">Customize Your Plan</h2>
          <div className="mb-6">
            <h3 className="font-bold mb-2">Dietary Preferences</h3>
            <div className="flex flex-wrap gap-4">
              {['Vegetarian', 'Vegan', 'Gluten-free', 'Low-carb', 'Keto'].map((pref) => (
                <motion.button
                  key={pref}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleDietaryPreference(pref)}
                  className={`px-4 py-2 rounded-full ${
                    dietaryPreferences.includes(pref)
                      ? 'bg-green-500 text-white'
                      : darkMode ? 'bg-gray-600 text-white' : 'bg-green-100 text-green-800'
                  } hover:bg-green-200 transition duration-300`}
                >
                  {pref}
                </motion.button>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <h3 className="font-bold mb-2">Allergies</h3>
            <input
              type="text"
              placeholder="Enter any allergies..."
              value={allergies.join(', ')}
              onChange={(e) => setAllergies(e.target.value.split(', '))}
              className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`}
            />
          </div>
          <div className="mb-6">
            <h3 className="font-bold mb-2">Daily Calorie Goal</h3>
            <input
              type="number"
              value={calorieGoal}
              onChange={(e) => setCalorieGoal(e.target.value)}
              className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`}
            />
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setWizardStep(2)}
            className={`${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white px-6 py-2 rounded-full transition duration-300`}
          >
            Next
          </motion.button>
        </motion.div>
      )}
      {wizardStep === 2 && (
        <motion.div
          key="step2"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
        >
          <h2 className="text-2xl font-bold mb-4">Choose Your Meals</h2>
          <p className="mb-4">Select up to {selectedPlan.mealsPerWeek} meals for your weekly plan.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {filteredMenu.map((meal) => (
              <motion.div
                key={meal.id}
                className={`bg-white rounded-lg shadow-md overflow-hidden cursor-pointer ${
                  selectedMeals.find(m => m.id === meal.id) ? 'border-2 border-green-500' : ''
                } ${darkMode ? 'bg-gray-700' : ''}`}
                whileHover={{ scale: 1.05 }}
                onClick={() => handleMealSelect(meal)}
              >
                <img src={meal.image} alt={meal.name} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <h3 className="font-bold mb-2">{meal.name}</h3>
                  <p className="text-sm text-gray-600 mb-2">{meal.cuisine}</p>
                  <p className="text-sm text-gray-600 mb-2">{meal.calories} calories</p>
                  <p className="text-sm font-bold text-green-600 mb-2">₹{meal.price}</p>
                  <div className="flex flex-wrap gap-2">
                    {meal.dietaryInfo.map((info, index) => (
                      <span key={index} className={`text-xs px-2 py-1 rounded-full ${darkMode ? 'bg-gray-600 text-white' : 'bg-gray-200'}`}>
                        {info}
                      </span>
                    ))}
                  </div>
                  <div className="mt-2">
                    <span className={`text-sm ${meal.sustainabilityScore >= 7 ? 'text-green-500' : 'text-yellow-500'}`}>
                      Sustainability Score: {meal.sustainabilityScore}/10
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="mt-6 flex justify-between items-center">
            <p className="text-xl font-bold">
              Selected Meals: {selectedMeals.length}/{selectedPlan.mealsPerWeek}
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setWizardStep(3)}
              className={`${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white px-6 py-2 rounded-full transition duration-300`}
            >
              Next
            </motion.button>
          </div>
        </motion.div>
      )}
      {wizardStep === 3 && (
        <motion.div
          key="step3"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
        >
          <h2 className="text-2xl font-bold mb-4">Delivery Information</h2>
          <div className="mb-6">
            <h3 className="font-bold mb-2">Delivery Address</h3>
            <input
              type="text"
              placeholder="Enter your delivery address"
              value={address}
              onChange={handleAddressChange}
              className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`}
            />
          </div>
          <div className="mb-6">
            <h3 className="font-bold mb-2">Subscription Duration</h3>
            <div className="flex space-x-4">
              <button
                onClick={() => handleSubscriptionDurationChange('week')}
                className={`px-4 py-2 rounded-full ${
                  subscriptionDuration === 'week'
                    ? 'bg-green-500 text-white'
                    : darkMode ? 'bg-gray-600 text-white' : 'bg-green-100 text-green-800'
                } hover:bg-green-200 transition duration-300`}
              >
                Weekly
              </button>
              <button
                onClick={() => handleSubscriptionDurationChange('month')}
                className={`px-4 py-2 rounded-full ${
                  subscriptionDuration === 'month'
                    ? 'bg-green-500 text-white'
                    : darkMode ? 'bg-gray-600 text-white' : 'bg-green-100 text-green-800'
                } hover:bg-green-200 transition duration-300`}
              >
                Monthly (10% off)
              </button>
            </div>
          </div>
          <div className="mb-6">
            <h3 className="font-bold mb-2">Subscription Total</h3>
            <p className="text-2xl font-bold text-green-500">
              ₹{getDiscountedPrice()} / {subscriptionDuration}
            </p>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setWizardStep(4)}
            className={`${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white px-6 py-2 rounded-full transition duration-300`}
          >
            Proceed to Payment
          </motion.button>
        </motion.div>
        )}
        {wizardStep === 4 && (
          <motion.div
            key="step4"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <h2 className="text-2xl font-bold mb-4">Payment</h2>
            <div className="mb-6">
              <h3 className="font-bold mb-2">Select Payment Method</h3>
              <div className="space-y-2">
                {['Credit Card', 'Debit Card', 'UPI', 'Net Banking', 'Wallet', 'Cryptocurrency'].map((method) => (
                  <div key={method} className="flex items-center">
                    <input
                      type="radio"
                      id={method}
                      name="paymentMethod"
                      value={method}
                      checked={paymentMethod === method}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      className="mr-2"
                    />
                    <label htmlFor={method}>{method}</label>
                  </div>
                ))}
              </div>
            </div>
            {paymentMethod && (
              <div className="mb-6">
                <h3 className="font-bold mb-2">Enter Payment Details</h3>
                {paymentMethod === 'Credit Card' || paymentMethod === 'Debit Card' ? (
                  <>
                    <input type="text" placeholder="Card Number" className={`w-full p-2 border rounded mb-2 ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`} />
                    <div className="flex space-x-2 mb-2">
                      <input type="text" placeholder="MM/YY" className={`w-1/2 p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`} />
                      <input type="text" placeholder="CVV" className={`w-1/2 p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`} />
                    </div>
                    <input type="text" placeholder="Name on Card" className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`} />
                  </>
                ) : paymentMethod === 'UPI' ? (
                  <input type="text" placeholder="UPI ID" className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`} />
                ) : (
                  <select className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : ''}`}>
                    <option>Select Bank</option>
                    <option>SBI</option>
                    <option>HDFC</option>
                    <option>ICICI</option>
                  </select>
                )}
              </div>
            )}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handlePayment}
              className={`${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white px-6 py-2 rounded-full transition duration-300`}
            >
              Confirm Payment
            </motion.button>
          </motion.div>
        )}
        {wizardStep === 5 && (
          <motion.div
            key="step5"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            className="text-center"
          >
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: 'spring', stiffness: 260, damping: 20 }}
            >
              <FaCheck className="text-green-500 text-5xl mb-4 mx-auto" />
            </motion.div>
            <h2 className="text-2xl font-bold mb-4">Subscription Confirmed!</h2>
            <p className="mb-6">Thank you for subscribing to our {selectedPlan.name} plan. Your first delivery will be on [Start Date].</p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setWizardStep(0)}
              className={`${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white px-6 py-2 rounded-full transition duration-300`}
            >
              Back to Home
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  const MealPlanner = () => (
    <div className={`max-w-6xl mx-auto bg-white rounded-lg shadow-lg p-8 mb-16 ${darkMode ? 'bg-gray-800 text-white' : ''}`}>
      <h2 className="text-2xl font-bold mb-4">Your Meal Planner</h2>
      {isLoggedIn ? (
        <>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
          />
          <div className="mt-4 flex justify-end">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white px-4 py-2 rounded-full transition duration-300`}
            >
              Customize Meals
            </motion.button>
          </div>
        </>
      ) : (
        <div className="text-center py-12">
          <FaLock className="text-gray-400 text-5xl mb-4 mx-auto" />
          <p className="text-xl mb-4">Please log in to view your meal planner</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsLoggedIn(true)}
            className={`${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600'} text-white px-6 py-2 rounded-full transition duration-300`}
          >
            Log In
          </motion.button>
        </div>
      )}
    </div>
  );

  const NutritionTracker = () => (
    <div className={`max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8 mb-16 ${darkMode ? 'bg-gray-800 text-white' : ''}`}>
      <h2 className="text-2xl font-bold mb-4">Nutrition Tracker</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h3 className="font-bold mb-2">Daily Intake</h3>
          <div className={`${darkMode ? 'bg-gray-700' : 'bg-gray-100'} p-4 rounded-lg`}>
            <p>Calories: 1800 / 2000</p>
            <p>Protein: 80g / 100g</p>
            <p>Carbs: 200g / 250g</p>
            <p>Fat: 60g / 70g</p>
          </div>
        </div>
        <div>
          <h3 className="font-bold mb-2">Macronutrient Breakdown</h3>
          <div className={`h-48 ${darkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-lg flex items-center justify-center`}>
            <p className={darkMode ? 'text-gray-300' : 'text-gray-500'}>Pie Chart Placeholder</p>
          </div>
        </div>
      </div>
    </div>
  );

  const AIFeature = () => (
    <div className={`max-w-6xl mx-auto ${darkMode ? 'bg-gray-800' : 'bg-gradient-to-r from-purple-400 via-pink-500 to-red-500'} rounded-lg shadow-lg p-8 mb-16 text-white`}>
      <h2 className="text-3xl font-bold mb-4">AI-Powered Meal Optimization</h2>
      <p className="text-xl mb-6">Experience the future of personalized nutrition with our advanced AI system.</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className={`${darkMode ? 'bg-gray-700' : 'bg-white bg-opacity-20'} p-6 rounded-lg`}>
          <FaBrain className="text-4xl mb-4" />
          <h3 className="text-xl font-bold mb-2">Personalized Recommendations</h3>
          <p>Our AI learns your preferences and nutritional needs to suggest the perfect meals for you.</p>
        </div>
        <div className={`${darkMode ? 'bg-gray-700' : 'bg-white bg-opacity-20'} p-6 rounded-lg`}>
          <FaChartBar className="text-4xl mb-4" />
          <h3 className="text-xl font-bold mb-2">Adaptive Meal Planning</h3>
          <p>Our AI adjusts your meal plan based on your progress, ensuring you always meet your health goals.</p>
        </div>
        <div className={`${darkMode ? 'bg-gray-700' : 'bg-white bg-opacity-20'} p-6 rounded-lg`}>
          <FaExchangeAlt className="text-4xl mb-4" />
          <h3 className="text-xl font-bold mb-2">Smart Substitutions</h3>
          <p>Don't like an ingredient? Our AI suggests perfect alternatives that maintain nutritional balance.</p>
        </div>
      </div>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`mt-8 ${darkMode ? 'bg-purple-600 hover:bg-purple-700' : 'bg-white text-purple-600 hover:bg-purple-100'} px-6 py-3 rounded-full text-lg font-semibold transition duration-300`}
      >
        Activate AI Assistant
      </motion.button>
    </div>
  );

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gradient-to-br from-green-50 to-blue-100'} py-12`}>
      <div className="fixed top-4 right-4 z-50">
        <button
          onClick={() => setDarkMode(!darkMode)}
          className={`p-2 rounded-full ${darkMode ? 'bg-yellow-400 text-gray-900' : 'bg-gray-800 text-white'}`}
        >
          {darkMode ? <FaSun /> : <FaMoon />}
        </button>
      </div>
      <Hero />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SubscriptionWizard />
        <MealPlanner />
        <NutritionTracker />
        <AIFeature />
      </div>
      {!isLoggedIn && <LoginModal />}
      {showAIQuestionnaire && <AIQuestionnaire />}
      <motion.div
        className="fixed bottom-4 right-4 z-50"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <button
          onClick={() => setShowFAQ(!showFAQ)}
          className={`p-4 rounded-full ${darkMode ? 'bg-purple-600 text-white' : 'bg-green-500 text-white'} shadow-lg`}
        >
          <FaQuestion />
        </button>
      </motion.div>
      {showFAQ && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          className={`fixed bottom-20 right-4 w-80 p-4 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'}`}
        >
          <h3 className="font-bold mb-2">FAQ</h3>
          <ul className="space-y-2">
            <li>How does the subscription work?</li>
            <li>Can I customize my meals?</li>
            <li>What if I need to pause my subscription?</li>
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default SubscriptionPage;