import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPepperHot, FaLeaf, FaUtensils, FaUserCircle, FaStar, FaCheck, FaShoppingCart } from 'react-icons/fa';
import { GiNoodles,  GiCook } from 'react-icons/gi';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import CountUp from 'react-countup';

// Import images
import chooseMealIcon from '../images/choose-meal-icon.png';
import placeOrderIcon from '../images/place-order-icon.png';
import swiftDeliveryIcon from '../images/swift-delivery-icon.png';
import heroImage from '../images/hero-image.jpg';
import chickenBiryaniImg from '../images/chicken-biryani.jpg';
import hyderabadiBiryaniImg from '../images/hyderabadi-biryani.jpg';
import southIndianImg from '../images/south-indian.jpg';
import vegBiryaniImg from '../images/veg-biryani.jpg';
import kitchenImage1 from '../images/kitchen1.jpg';
import kitchenImage2 from '../images/kitchen2.jpg';
import kitchenImage3 from '../images/kitchen3.jpg';
import biryaniBlissVideo from '../images/biryanibliss.mp4';
import dragonWokVideo from '../images/aaq.mp4';
import dosaDelightVideo from '../images/aaws.mp4';

const kitchens = [
  { 
    name: 'Biryani Bliss', 
    image: kitchenImage1,
    cuisineType: 'Indian',
    rating: 4.5,
    specialty: 'Authentic Hyderabadi Biryani', 
    offer: '20% off on first order',
    icon: GiNoodles,
    video: biryaniBlissVideo

  },
  { 
    name: 'Dragon Wok', 
    image: kitchenImage2,
    cuisineType: 'Indo-Chinese',
    rating: 4.3,
    specialty: 'Sizzling Indo-Chinese', 
    offer: 'Free drink with every meal',
    icon: FaPepperHot,
    video: dragonWokVideo

  },
  { 
    name: 'Dosa Delight', 
    image: kitchenImage3,
    cuisineType: 'South Indian',
    rating: 4.7,
    specialty: 'South Indian Specialties', 
    offer: 'Buy 2 Get 1 free on weekends',
    icon: GiCook,
    video: dosaDelightVideo

  },
];

const featuredMeals = [
  { name: 'Classic Thali', image: vegBiryaniImg, description: 'A complete Indian meal with a variety of dishes', price: '₹280', prepTime: '30 mins' },
  { name: 'Paneer Butter Masala', image: chickenBiryaniImg, description: 'Creamy and rich North Indian favorite', price: '₹220', prepTime: '25 mins' },
  { name: 'South Indian Platter', image: southIndianImg, description: 'A delightful assortment of South Indian specialties', price: '₹250', prepTime: '35 mins' },
];

const subscriptionPlans = [
  { name: 'Basic', price: '₹1500', meals: 'One meal per day (Lunch)', description: 'Perfect for those who need a hearty lunch.', features: ['7 different meals per week', 'Free delivery', '24/7 customer support'] },
  { name: 'Standard', price: '₹2000', meals: 'Two meals per day (Lunch & Dinner)', description: 'Ideal for busy professionals.', features: ['14 different meals per week', 'Free delivery', '24/7 customer support', 'Customize your menu'] },
  { name: 'Premium', price: '₹3500', meals: 'Three meals per day (Breakfast, Lunch & Dinner)', description: 'Complete nutrition for your entire day.', features: ['21 different meals per week', 'Free delivery', '24/7 customer support', 'Customize your menu', 'Weekly chef specials'] },
];

const testimonials = [
  { name: 'Amit Sharma', role: 'Software Engineer', quote: 'Sai Green has made my busy workdays so much easier. Their meals are delicious and always delivered on time.' },
  { name: 'Priya Patel', role: 'Marketing Manager', quote: 'I love the variety of options Sai Green offers. It is like having a new restaurant experience every day!' },
  { name: 'Rajesh Kumar', role: 'Retired Teacher', quote: 'The elderly care meals are perfect for me. Nutritious, tasty, and easy to reheat. Thank you, Sai Green!' },
];

const faqs = [
  { question: 'What areas do you deliver to?', answer: 'We currently deliver to all areas within Bokaro Steel City. Were continuously expanding our delivery zones, so please check our website for the most up-to-date information.' },
  { question: 'How do I place an order?', answer: 'You can place an order through our website or mobile app. Simply browse our menu, select your desired items, and proceed to checkout. You can also call our customer service number for assistance with placing an order.' },
  { question: 'Do you offer vegetarian and vegan options?', answer: 'Yes, we have a wide range of vegetarian options available. We also offer some vegan dishes and are continually expanding our menu to cater to various dietary preferences.' },
  { question: 'What are your food safety measures?', answer: 'We follow strict hygiene protocols in all our kitchens. Our staff undergoes regular health check-ups and wears appropriate protective gear. We also ensure contactless delivery for your safety.' },
  { question: 'Can I customize my meal plan?', answer: 'Absolutely! Our Standard and Premium subscription plans offer menu customization. You can choose your preferred dishes for each meal of the week.' },
];

const HomePage = () => {
  const [activeKitchen, setActiveKitchen] = useState(0);
  const [liveOrders, setLiveOrders] = useState([]);
  const [trendingDishes, setTrendingDishes] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveKitchen((prev) => (prev + 1) % kitchens.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const generateLiveOrders = () => {
      const newOrders = Array.from({ length: 5 }, () => ({
        id: Math.random().toString(36).substr(2, 9),
        dish: `${['Veg', 'Chicken', 'Paneer', 'Mutton'][Math.floor(Math.random() * 4)]} ${['Biryani', 'Curry', 'Thali', 'Noodles'][Math.floor(Math.random() * 4)]}`,
        status: ['Preparing', 'Cooking', 'Packaging', 'Out for Delivery'][Math.floor(Math.random() * 4)],
        estimatedTime: Math.floor(Math.random() * 30) + 15,
        kitchen: kitchens[Math.floor(Math.random() * kitchens.length)].name,
        customerRating: (Math.random() * 2 + 3).toFixed(1),
        temperature: Math.floor(Math.random() * 20) + 60,
        quantity: Math.floor(Math.random() * 3) + 1,
      }));
      setLiveOrders(newOrders);
    };

    const generateTrendingDishes = () => {
      const dishes = [
        { name: 'Hyderabadi Biryani', price: '₹250', rating: 4.8, orders: 120, image: hyderabadiBiryaniImg },
        { name: 'Butter Chicken', price: '₹280', rating: 4.7, orders: 98, image: chickenBiryaniImg },
        { name: 'Masala Dosa', price: '₹120', rating: 4.6, orders: 85, image: southIndianImg },
        { name: 'Paneer Tikka', price: '₹220', rating: 4.5, orders: 76, image: vegBiryaniImg },
        { name: 'Chilli Chicken', price: '₹260', rating: 4.7, orders: 92, image: chickenBiryaniImg },
      ];
      setTrendingDishes(dishes);
    };

    generateLiveOrders();
    generateTrendingDishes();

    const interval = setInterval(() => {
      generateLiveOrders();
      generateTrendingDishes();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const Hero = () => (
    <div className="relative bg-gradient-to-r from-green-400 to-blue-500 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Delicious meals from</span>{' '}
                <span className="block text-green-200 xl:inline">Bokaro's finest cloud kitchen</span>
              </h1>
              <p className="mt-3 text-base text-green-100 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-auto">
                Enjoy a wide variety of cuisines, from South Indian delicacies to North Indian favorites, all prepared with care and delivered to your doorstep.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <Link to="/menu" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-green-50 md:py-4 md:text-lg md:px-10 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                    View Menu
                  </Link>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <Link to="/subscription" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 md:py-4 md:text-lg md:px-10 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                    Subscribe
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={heroImage} alt="Delicious meal spread" />
      </div>
    </div>
  );

  const Stats = () => (
    <div className="bg-green-600 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
          {[
            { label: 'Happy Customers', value: 10000, icon: FaUserCircle },
            { label: 'Meals Served', value: 500000, icon: FaUtensils },
            { label: 'Cities', value: 5, icon: FaLeaf },
            { label: 'Chefs', value: 50, icon: GiCook }
          ].map((stat, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg p-6">
              {React.createElement(stat.icon, { className: "text-4xl text-green-500 mx-auto mb-4" })}
              <CountUp end={stat.value} duration={2.5} separator="," className="text-4xl font-bold text-green-600" />
              <p className="mt-2 text-lg text-gray-600">{stat.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const KitchenShowcase = ({ activeKitchen, setActiveKitchen }) => (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Our Kitchens in Action</h2>
        <div className="relative h-96 bg-gray-200 rounded-lg overflow-hidden">
          <div className="absolute top-4 left-4 flex items-center z-10">
            <span className="flex h-3 w-3 relative">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
            </span>
            <span className="ml-2 text-sm font-semibold text-red-600">LIVE</span>
          </div>
          <AnimatePresence>
            <motion.div
              key={activeKitchen}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="absolute inset-0 flex items-center justify-center"
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                className="absolute inset-0 w-full h-full object-cover"
              >
                <source src={kitchens[activeKitchen].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="relative text-center text-white z-10">
                <div className="text-6xl mb-4">
                  {React.createElement(kitchens[activeKitchen].icon)}
                </div>
                <h3 className="text-2xl font-bold">{kitchens[activeKitchen].name}</h3>
                <p className="mt-2 text-lg">{kitchens[activeKitchen].specialty}</p>
                <p className="mt-4 font-semibold text-green-300">{kitchens[activeKitchen].offer}</p>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="mt-4 bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition duration-300"
                >
                  Explore Menu
                </motion.button>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
        <div className="mt-8 flex justify-center space-x-2">
          {kitchens.map((kitchen, index) => (
            <button
              key={kitchen.name}
              className={`w-3 h-3 rounded-full ${index === activeKitchen ? 'bg-green-500' : 'bg-gray-300'}`}
              onClick={() => setActiveKitchen(index)}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
  const LiveOrders = () => (
    <div className="bg-green-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Live Orders</h2>
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {liveOrders.map((order) => (
            <SwiperSlide key={order.id}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-white rounded-lg shadow-md p-6 border-l-4 border-green-500"
              >
                <div className="flex items-center mb-4">
                  <FaUserCircle className="text-3xl text-gray-400 mr-2" />
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">{order.dish}</h3>
                    <p className="text-sm text-gray-600">{order.kitchen}</p>
                  </div>
                </div>
                <p className="mt-2 text-sm text-gray-600">Status: {order.status}</p>
                <div className="mt-4 h-2 bg-gray-200 rounded-full">
                  <div 
                    className="h-2 bg-green-500 rounded-full" 
                    style={{width: `${['Preparing', 'Cooking', 'Packaging', 'Out for Delivery'].indexOf(order.status) * 25 + 25}%`}}
                  ></div>
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <p className="text-sm text-gray-600">Estimated time: {order.estimatedTime} mins</p>
                  <p className="text-sm text-gray-600">Quantity: {order.quantity}</p>
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <p className="text-sm text-gray-600">Temperature: {order.temperature}°C</p>
                  <div className="flex items-center">
                    <FaStar className="text-yellow-400 mr-1" />
                    <p className="text-sm text-gray-600">{order.customerRating}</p>
                  </div>
                </div>
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );

  const HowItWorks = () => (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            { icon: chooseMealIcon, title: "Choose Your Meal", description: "Embark on a culinary journey through our diverse menu" },
            { icon: placeOrderIcon, title: "Place Your Order", description: "Seamlessly confirm your selection and complete your purchase" },
            { icon: swiftDeliveryIcon, title: "Swift Delivery", description: "Experience the convenience of doorstep delivery" }
          ].map((step, index) => (
            <motion.div
              key={index}
              className="bg-green-50 rounded-lg shadow-lg p-6 text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <img src={step.icon} alt={step.title} className="w-16 h-16 mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300"
              >
                Learn More
              </motion.button>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );

  const FeaturedMeals = () => (
    <div className="bg-green-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">Featured Meals</h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Discover Our Specialties</p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">Explore our curated selection of mouth-watering dishes, prepared with the finest ingredients and culinary expertise.</p>
        </div>
        <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {featuredMeals.map((meal, index) => (
            <motion.div
              key={meal.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg overflow-hidden shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            >
              <img className="h-48 w-full object-cover" src={meal.image} alt={meal.name} />
              <div className="p-6">
                <h3 className="text-lg font-medium text-gray-900">{meal.name}</h3>
                <p className="mt-2 text-sm text-gray-500">{meal.description}</p>
                <div className="mt-4 flex justify-between items-center">
                  <span className="text-green-600 font-semibold">{meal.price}</span>
                  <span className="text-sm text-gray-500">Prep time: {meal.prepTime}</span>
                </div>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="mt-4 w-full bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300"
                >
                  Order Now
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );

  const SubscriptionPlans = () => (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">Pricing</h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Subscription Plans</p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">Choose a plan that fits your lifestyle and budget</p>
        </div>
        <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          {subscriptionPlans.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col ${selectedPlan === plan.name ? 'border-green-500 ring-2 ring-green-500' : ''}`}
            >
              <div className="flex-1">
                <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
                <p className="mt-4 flex items-baseline text-gray-900">
                  <span className="text-5xl font-extrabold tracking-tight">{plan.price}</span>
                  <span className="ml-1 text-xl font-semibold">/month</span>
                </p>
                <p className="mt-6 text-gray-500">{plan.meals}</p>
                <ul className="mt-6 space-y-6">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex">
                      <FaCheck className="flex-shrink-0 w-6 h-6 text-green-500" />
                      <span className="ml-3 text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedPlan(plan.name)}
                className={`mt-8 block w-full bg-green-500 border border-transparent rounded-md py-3 px-6 text-center font-medium text-white hover:bg-green-600 ${selectedPlan === plan.name ? 'bg-green-600' : ''}`}
              >
                {selectedPlan === plan.name ? 'Selected' : 'Select Plan'}
              </motion.button>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );

  const Testimonials = () => (
    <div className="bg-green-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">What Our Customers Say</h2>
        <div className="grid gap-8 lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={testimonial.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg p-6"
            >
              <div className="flex items-center mb-4">
                <img className="h-12 w-12 rounded-full" src={`/api/placeholder/100/100`} alt={testimonial.name} />
                <div className="ml-4">
                  <h4 className="text-lg font-bold text-gray-900">{testimonial.name}</h4>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                </div>
              </div>
              <p className="mt-4 text-gray-600 italic">"{testimonial.quote}"</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );

  const FAQ = () => (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-green-600 font-semibold tracking-wide uppercase">FAQs</h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Frequently Asked Questions</p>
        </div>
        <div className="mt-12 max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="mt-8 border-t border-gray-200 pt-6"
            >
              <dt className="text-lg leading-7">
                <button className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
                  <span className="font-medium text-gray-900">{faq.question}</span>
                  <span className="ml-6 h-7 flex items-center">
                    <svg className="h-6 w-6 transform rotate-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </span>
                </button>
              </dt>
              <dd className="mt-2 pr-12">
                <p className="text-base text-gray-500">{faq.answer}</p>
              </dd>
            </motion.div>
          ))}
        </div>
        <div className="mt-8 text-center">
          <Link to="/faq" className="text-base font-medium text-green-600 hover:text-green-500">
            View all FAQs
            <span aria-hidden="true"> →</span>
          </Link>
        </div>
      </div>
    </div>
  );

  const CTASection = () => (
    <div className="bg-gradient-to-r from-green-500 to-emerald-600">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          <span className="block">Ready to try Sai Green?</span>
          <span className="block text-emerald-900">Start your culinary journey today.</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <Link to="/menu" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-green-50 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
              View Menu
            </Link>
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <Link to="/subscription" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
              Subscribe Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-emerald-100">
      <Hero />
      <Stats />
      <KitchenShowcase activeKitchen={activeKitchen} setActiveKitchen={setActiveKitchen} />      <LiveOrders />
      <HowItWorks />
      <FeaturedMeals />
      <SubscriptionPlans />
      <Testimonials />
      <FAQ />
      <CTASection />

      {/* Floating Cart Button */}
      <motion.div
        className="fixed bottom-4 right-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1 }}
      >
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
        >
          <FaShoppingCart className="text-2xl" />
        </motion.button>
      </motion.div>

      {/* Floating Customer Support Button */}
      <motion.div
        className="fixed bottom-20 right-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.2 }}
      >
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="bg-white text-green-600 p-4 rounded-full shadow-lg hover:bg-green-50 transition duration-300 flex items-center"
        >
          <FaUserCircle className="text-2xl mr-2" />
          <span className="hidden md:inline">Customer Support</span>
        </motion.button>
      </motion.div>

      {/* Scroll to Top Button */}
      <AnimatePresence>
        {(
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
           
            className="fixed bottom-36 right-4 bg-green-500 text-white p-2 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
          >
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HomePage;